import React, { Component } from 'react';
import { Ajax } from '../ajax/Ajax';
import {isMobile, isTablet} from 'react-device-detect';
import shuffle from 'shuffle-array'
import {sFunctions} from './StaticFunctions';

/*
Using: https://www.npmjs.com/package/react-card-flip

https://reactjsexample.com/flipping-cards-for-your-react-projects/
https://github.com/mzabriskie/react-flipcard
let navigationChanged = false;
shouldComponentUpdate(){
    if (navigationChanged === true) {
        this.updateWindowDimensions();
        this.updateImageGridDimensions();
        console.log(true)
        console.log(window.innerHeight)
        navigationChanged = false;
    }
    return true
}
*/
let allImages = null;
let landingMainPages = null;
class Landing extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.imageLastIndex = -1;
        this.mouseHoverImage = -1;
        this.allLandingItems = [];
        this.LandingMainPages = [];
        this.imgSize = 0;
        this.state = {
            LandingMainPages: null,
            landingTitle: null,
            loaded: false,
            lastItem: null,
            loadingItems: false,
            width: 0,
            height: 0,
            imageGridWidth: 0,
            imageGridHeight: 0,
            landscape: window.matchMedia("(orientation: landscape)").matches,
            myElement: null,
            maxItems: sFunctions.mobileDevice() === true ? 14 : 48,
            mobileTabletStyle: null,
            imageSize: null,
            imgCounter: 0,
            arrayImages: [],
            gridViewWaiting: [],
            contentWrapperHeight: null,
            contentWrapperWidth:0
    };
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
        this.reloadImages = this.reloadImages.bind(this);
    }
    goBack() {
       // console.log(this.props)
        /*const { navigation } = this.props;
        navigation.history.goBack();
        navigation.state.params.onSelect({ selected: true });*/
    }

    makePhotoGrid(itemArray) {
        let gridView = [];
        let gridViewWaiting = [];
        let iSum = 0;
        let elements = itemArray;
        for (let el in elements) {
            if (elements.hasOwnProperty(el)) {
                let elm = elements[el].title.rendered;
                const img = elements[el].better_featured_image;
                let parentTitle = "";
                let parentSlug = "";
                for (let i = 0; i < this.LandingMainPages.length; ++i) {
                    if (this.LandingMainPages[i].id === elements[el].parent) {
                        parentTitle = this.LandingMainPages[i].title.rendered;
                        parentSlug = this.LandingMainPages[i].slug;
                    }
                }
                // //onClick={() => {window.location.href = `/Portfolio/${parentTitle}/${elements[el].id}`}}
                if (![null, "cover", "text"].includes(elm) && !(img === null) && parentSlug.indexOf("corp-") !== 0) {
                    const index = iSum;
                    const elEl = el;
                    const parentSlugParentSlug = parentSlug;
                    const elementsElements = elements;
                    let child = (
                        <div key={index} className={"gridImageBox"} id={index}
                             onMouseEnter={() => this.mouseHoverImage = index}
                             onMouseLeave={() => this.mouseHoverImage = -1}
                        >
                            <a className={"gridImageLink"}
                               onClick={() => {
                                   this.props.history.push(`/Portfolio/${parentSlugParentSlug}/${elementsElements[elEl].id}`)
                               }}
                            >
                                <img
                                    alt={elementsElements[elEl].title.rendered}
                                    style={{
                                        objectFit: "cover",
                                        width: this.imgSize,
                                        height: this.imgSize,
                                    }}
                                    src={img.media_details.sizes.medium.source_url}
                                    className={"gridImage"}
                                />
                                <div
                                    className={"gridImageText"}>
                                    <p>
                                        {sFunctions.clearAmp(parentTitle)}
                                    </p>
                                    <p>
                                        {sFunctions.clearAmp(elementsElements[el].title.rendered)}
                                    </p>
                                </div>
                            </a>
                        </div>);
                    if (!(iSum >= this.state.maxItems)) {
                        gridView.push(child);
                    } else {
                        gridViewWaiting.push(child);
                    }
                    iSum++;
                    //console.log(Math.floor(Math.random() * this.state.maxItems) - 1)
                }
            }
        }
        let sArray = shuffle(gridView);
        let waitingImages = shuffle(gridViewWaiting);
        return [sArray, waitingImages]
        //this.setState({arrayImages: sArray, gridViewWaiting: waitingImages});
        //return sArray
    }

    changeImage(){
        let obj = this;
        function myTimer() {
            let visible = false;
            while (visible === false) {
                if (obj._isMounted && obj.state.gridViewWaiting.length > 0) {
                    let gridViewWaiting = obj.state.gridViewWaiting;
                    let arrayImages = obj.state.arrayImages;
                    const randomIndex = sFunctions.randomNumerExclude(obj.imageLastIndex, obj.state.maxItems);
                    obj.imageLastIndex = randomIndex;
                    const childDOMReplace = obj.myElement.childNodes[randomIndex];
                    const key = Number(arrayImages[randomIndex].props.id);
                    const isVisible = sFunctions.isVisible(childDOMReplace);
                    if ((key !== obj.mouseHoverImage) && isVisible) {
                        const oldItem = arrayImages[randomIndex];
                        arrayImages[randomIndex] = gridViewWaiting[0];
                        gridViewWaiting.push(oldItem);
                        gridViewWaiting.shift();
                        obj.setState({arrayImages: arrayImages, gridViewWaiting: gridViewWaiting})
                    }
                   if (isVisible) visible = true;
               }
           }
       }
       if(this._isMounted) {this.setTimerNode = setTimeout(() => {this.interval = setInterval(function(){myTimer(); }, 1500)}, 3000);}}

   componentWillUnmount(){
       this._isMounted = false;
       window.clearInterval(this.interval);
       window.clearTimeout(this.setTimerNode);
       allImages = this.allLandingItems;
       landingMainPages = this.LandingMainPages;
   }

   allLandingImages(index) {
       let itemArray = this.allLandingItems;
       Ajax.getChildrenPages(index).then(section => {
           if(section) {
               for (let i in  section) {
                   if (section.hasOwnProperty(i)) {
                       let subSection = section[i];
                       itemArray.push(subSection);
                       const arrayImg = this.LandingMainPages ? this.makePhotoGrid(itemArray) : null;
                       this.setState({
                           loadingItems: true,
                           arrayImages: arrayImg ? arrayImg[0] : null,
                           gridViewWaiting: arrayImg ? arrayImg[1] : null
                       });
                   }
               }
           }
       });
   }

   handleScroll(e) {
       let imageGrid = this.myElement;
       let delta = Math.max(-1, Math.min(1, (e.deltaY || - e.deltaMode)));
       imageGrid.scrollLeft += (delta*60);
       //e.preventDefault();
   }
    componentWillMount() {
        window.removeEventListener('orientationchange', this.updateWindowDimensions);
        window.removeEventListener('resize', this.updateWindowDimensions);
        window.removeEventListener('load', this.updateWindowDimensions);
        window.removeEventListener('resize', this.reloadImages);
        window.removeEventListener('orientationchange', this.reloadImages);
    }

    componentDidMount() {
        /*if(this.allImages) {
            this.setState({allLandingItems: this.allImages});
        }*/
       this._isMounted = true;
       this.updateWindowDimensions();
       window.addEventListener('load', this.updateWindowDimensions);
       window.addEventListener('resize', this.updateWindowDimensions);
       window.addEventListener('orientationchange', this.updateWindowDimensions);
       window.addEventListener('resize', this.reloadImages);
       window.addEventListener('orientationchange', this.reloadImages);
       if(allImages) {
            this.allLandingItems = allImages;
            this.LandingMainPages = landingMainPages;
            let arrayImg = this.makePhotoGrid(allImages);
            this.setState({arrayImages: arrayImg ? arrayImg[0] : null,
                gridViewWaiting: arrayImg ? arrayImg[1] : null
            });
            //this.reloadImages();
        } else {
            Ajax.getAllSubPages().then(section => {
                if (this._isMounted && section) {
                    if (section.length > 0) {
                        this.LandingMainPages = section;
                        for (let index in section) {
                            if (section.hasOwnProperty(index)) {
                                this.allLandingImages(section[index].id);
                            }
                        }
                    }
                }
            });
        }
        this.changeImage();
   }

   updateWindowDimensions() {
       let windowWidth = window.innerWidth;
       let windowHeight = window.innerHeight;
       let gridWidth = windowWidth;
       let gridHeight = windowHeight;
       if (!(isMobile || isTablet || (window.innerWidth < 1101))) {
           gridHeight -= document.getElementsByClassName('MainMenu')[0].clientHeight;
       }
       if (isTablet || isMobile) {
           if (isTablet && isMobile) {
               this.imgSize = gridWidth / 3;
           }
           if (isMobile && !isTablet) {
               this.imgSize = gridWidth / 2;
           }
       } else {
           if (gridHeight >= 750) {
               this.imgSize = gridHeight / 4;
           }
           if (gridHeight < 750) {
               this.imgSize = gridHeight / 3;
           }
           if (gridWidth < 1101) {
               this.imgSize = gridWidth / 3;
           }
       }
       this.setState({
           imageGridWidth: gridWidth,
           imageGridHeight: gridHeight,
           landscape: window.matchMedia("(orientation: landscape)").matches,
           mobileTabletStyle: this.setMobileStyle(),
           contentWrapperHeight: document.getElementsByClassName('ContentWrapper')[0].offsetHeight,
           contentWrapperWidth: window.innerWidth,
       });
   }
       reloadImages(){
           let arrayImg = this.makePhotoGrid(this.allLandingItems);
           this.setState({
               arrayImages: arrayImg ? arrayImg[0]: null,
               gridViewWaiting: arrayImg ? arrayImg[1] : null,
           })
       }


    setMobileStyle(){
        const MobileStyle = {
            mobile: {
                portrait: {gridTemplateColumns: "repeat(2, 1fr)", overflow: "hidden scroll", gridTemplateRows: "unset", gridAutoRows: "1fr", gridAutoFlow: "row"},
                landscape: {gridTemplateColumns: "repeat(2, 1fr)", overflow: "hidden scroll", gridTemplateRows: "unset", gridAutoRows: "1fr", gridAutoFlow: "row"},
            },
            tablet: {
                portrait: {gridTemplateColumns: "repeat(3, 1fr)", overflow: "hidden scroll", gridTemplateRows: "unset", gridAutoRows: "1fr", gridAutoFlow: "row"},
                landscape: {gridTemplateColumns: "repeat(3, 1fr)", overflow: "hidden scroll", gridTemplateRows: "unset", gridAutoRows: "1fr", gridAutoFlow: "row"},
            },
            other: {
                portrait: {gridTemplateColumns: "repeat(3, 1fr)", overflow: "hidden scroll", gridTemplateRows: "unset", gridAutoRows: "1fr", gridAutoFlow: "row"},
                landscape: {gridTemplateColumns: "repeat(3, 1fr)", overflow: "hidden scroll", gridTemplateRows: "unset", gridAutoRows: "1fr", gridAutoFlow: "row"},
            }
        };
        let style;
        if (this.state.landscape) {
            if (isMobile || isTablet) {style = isTablet ? MobileStyle.tablet.landscape : MobileStyle.mobile.landscape
            } else {style = MobileStyle.tablet.landscape}
        } else {
            if (isMobile || isTablet) {style =  isTablet ? MobileStyle.tablet.portrait : MobileStyle.mobile.portrait
            } else {style = MobileStyle.tablet.portrait}}
        return style;
    }
   render() {
       if(isMobile || isTablet || (window.innerWidth < 1101)) {
           return(
               <div
                   ref={div => this.myElement = div}
                   id={'imageGrid'}
                   className="grid"
                   style={this.state.mobileTabletStyle}
               >
                   {this.state.arrayImages}
               </div>
           )
       } else {
           return(
               <div ref={div => this.myContent = div}>
                   <div
                       ref={div => this.myElement = div}
                       id={'imageGrid'}
                       className="grid"
                       onWheel={(e) => this.handleScroll(e)}
                   >
                       {this.state.arrayImages}
                   </div>
                   {sFunctions.makeImage(this.state.contentWrapperWidth, this.state.contentWrapperHeight, this.myContent)}
               </div>
           )
       }

       /* <Prompt
                       when={isBlocking}
                       message={location =>
                           `Are you sure you want to go to ${location.pathname}`
                       }
                   />



       if (content.length) {

       } else {
           return (
               <div
                   id={'imageGrid'}
                   className="grid"
                   style={{
                       width: this.state.width,
                       height: this.state.height,
                       backgroundColor: "red",
                       opacity: 10,
                       position: "fixed",
                       zIndex: 10,
                   }}
               >
               </div>
           )
       }*/
    }
}
//{content.length ? content : loading}
export {Landing};


