import React, { Component } from 'react';
import {Ajax} from "../ajax/Ajax";
import ReactHtmlParser from 'react-html-parser';
import {sFunctions} from './StaticFunctions';

class Contact extends Component {
    constructor(props) {
        super(props);
        this.state = {
            content: "", contentWrapperHeight: null,imgWidth:0,contentWrapperWidth:0, imageDrag: null};
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    updateWindowDimensions(){
        if(sFunctions.mobileDevice()){
            this.setState({contentWrapperHeight: "100%"})
        } else {
            this.setState({
                contentWrapperHeight: document.getElementsByClassName('ContentWrapper')[0].offsetHeight,
                contentWrapperWidth: window.innerWidth,
            })}}

    componentWillMount(){
        window.removeEventListener('resize', this.updateWindowDimensions);
        window.removeEventListener('orientationchange', this.updateWindowDimensions);}

	componentDidMount() {
        window.addEventListener('resize', this.updateWindowDimensions);
        window.addEventListener('orientationchange', this.updateWindowDimensions);
        this.myContent.addEventListener('resize', this.updateWindowDimensions);
        let obj = this;
        Ajax.getContactContent().then(function(PageContent) {
            obj.setState({content: PageContent});
            obj.updateWindowDimensions();
        });
        this.updateWindowDimensions();}

	render() {
		return (
            <div ref={div => this.myContent = div} style={{height: this.state.contentWrapperHeight}}>
                <div className="Contact">{ReactHtmlParser(this.state.content)}</div>
                {sFunctions.makeImage(this.state.contentWrapperWidth, this.state.contentWrapperHeight, this.myContent)}
            </div>
		);
	}
}

export { Contact };