import React, { Component } from 'react';
import { Ajax } from '../ajax/Ajax';
import ReactHtmlParser from 'react-html-parser';
import {sFunctions} from './StaticFunctions';
import Slider from "react-slick";
import { Textfit } from 'react-textfit';

let sliderIndexGo = true;
//center items: http://kenwheeler.github.io/slick/
//https://react-slick.neostack.com/docs/get-started

class Carousel extends Component {

    constructor(props) {
        super(props);
        this.state = {
            myElement: null, myHeight: null, gridHeight: null, textBlockHeight: null, imageHeight: null, title: null,
            bodyText:null, content:[], images: [], text: [], render: false, myCarouselTextBlock: null, landscape: false,
            mobileStyle: null, alternateText: null, setHeightP: 0,};
       this.updateWindowDimensions = this.updateWindowDimensions.bind(this);

    }

    componentWillMount() {
        this.setState({alternateText: (this.props.match.params.slug).replace("corp-", "").replace("-", " ")});
        if (window.matchMedia("(orientation: landscape)").matches && sFunctions.mobileDevice()) {
            this.setState({landscape: true})} else {this.setState({landscape: false})}
        window.removeEventListener('resize', this.updateWindowDimensions);
        window.removeEventListener('orientationchange', this.updateWindowDimensions);
    }

    handleScroll(e) {
        if (sFunctions.mobileDevice() === false) {
            sliderIndexGo = false;
            if (e.deltaY > 0) {
                this.slider.slickNext()
            } else {
                this.slider.slickPrev()
            }}};

    componentWillUnmount() {window.clearInterval(this.interval);}

    changeTextTitle(){
        this.interval = setInterval(function(){ myTimer(); }, 1000);
        let obj = this;
        const title = (this.props.match.params.slug).replace("-", " ");
        function myTimer() {
            let current = obj.state.alternateText;
            let text;
            if( current === "Click for more"){
                text = title
            } else {
                text = "Click for more"
            }
            obj.setState({alternateText: text});
        }
    }

    updateImageGridDimensions() {
        const gridHeight = window.innerHeight;
        if (this.myCarouselTextBlock) {
            const textBlockHeight = this.myCarouselTextBlock.offsetHeight;
            let mainMenuHeight = null;
            if (sFunctions.mobileDevice()) {
                mainMenuHeight = document.getElementsByClassName('mobileContactAboutBlock')[0].offsetHeight;
            } else {
                mainMenuHeight = document.getElementsByClassName('MainMenu')[0].offsetHeight;
            }
            const imageHeight = gridHeight - textBlockHeight - mainMenuHeight;
            this.setState({imageGridHeight: gridHeight, textBlockHeight: textBlockHeight, imageHeight: imageHeight})
        }
    }

    componentDidMount(){
        window.addEventListener('resize', this.updateWindowDimensions);
        window.addEventListener('orientationchange', this.updateWindowDimensions);
        this.myCarouselTextBlock.addEventListener('resize', this.updateWindowDimensions);
        const obj = this;
        Ajax.getPageItem(this.props.match.params.index).then(function(section) {
                obj.setState({
                    section: section,
                    content: section.content.rendered,
                    title: section.title.rendered,
                    render: false
                });
            obj.transformHTML();
            obj.updateImageGridDimensions()
        });
        this.changeTextTitle();
    }

    updateWindowDimensions(){
        this.updateImageGridDimensions();
        if (window.matchMedia("(orientation: landscape)").matches && sFunctions.mobileDevice()) {
            this.setState({landscape: true})} else {this.setState({landscape: false})}
    }


    transformHTML() {
        let textElements = [];
        let imageElements = [];
        const parser = new DOMParser();
        const parsedHtml = parser.parseFromString(this.state.content, 'text/html');
        const pElement = parsedHtml.getElementsByTagName('p');
        const imgElement = parsedHtml.getElementsByTagName('IMG');
        for (let i = 0; i < imgElement.length; i++) {
            imageElements.push(
                    <img
                        key={`img-${i}`}
                        src={imgElement[i].src}
                        alt={imgElement[i].alt}/>
            );}
        for (let i = 0; i < pElement.length; i++) {
            let containsP =  pElement[i].getElementsByTagName('IMG');
            if(containsP.length === 0) {
                textElements.push(pElement[i].innerHTML);
            }
        }
        this.setState({text:ReactHtmlParser(textElements.toString()), images:imageElements});
        this.updateImageGridDimensions();

    }

    shouldComponentUpdate () {
        if (this.slider &&  sliderIndexGo === true) {
            this.slider.slickGoTo(0);
        }
        return true
    }

    render() {
        let settings = {
            className: "slider variable-width",
            ref: slider => (this.slider = slider),
            infinite: true,
            centerMode: true,
            slidesToScroll: 1,
            slidesToShow: 1,
            initialSlide: 0,
            variableWidth: true,
            swipeToSlide: true,
            afterChange: () =>  sliderIndexGo = false
        };
        const height = sFunctions.setHeight();
        let textFiSettings =  {
            max:  height,
            forceSingleModeWidth: false,
            mode: "single"
        };
        let imgContent;
        let images = this.state.images;

        let title = (
            <div className="CarouselTitle">
                <Textfit  {...textFiSettings} >
                    <p style={{height:height }}>
                        <a
                            title={"Go to portfolio"}
                            className={"changeText"}
                            onClick={() => {window.location.href = `/Portfolio/${this.props.match.params.slug}`}}
                        >
                            {sFunctions.clearAmp(this.state.alternateText)}
                        </a>
                    </p>
                </Textfit>
                <p>{sFunctions.clearAmp(this.state.title)}</p>
            </div>
        );
        if (sFunctions.mobileDevice() && (this.state.landscape === false)) {
            imgContent = (
                <div className="CarouselImages" style={{width: "100%"}} >
                    {images}
                </div>
            );

        } else{
            imgContent= (
                <div className="CarouselImages" style={{height: this.state.imageHeight}}>
                    <Slider {...settings}>
                        {images}
                    </Slider>)
                </div>
            ) ;
        }
        return (
            <div className="Carousel" onWheel={(e) => this.handleScroll(e)} >
                <div className="CarouselTextBlock"  ref={div => this.myCarouselTextBlock = div}>
                    {title}
                    <div ref={div => this.myBodyText = div} className="CarouselBodyText">{this.state.text}</div>
                </div>
                {imgContent}
            </div>
        );
    }
}

export { Carousel };