import 'whatwg-fetch';

const wpBase = "http://kimtdesign.info/wordpress";
const menuPageId = 16;

class AjaxCalls {

    getJson(url) {
        return fetch(url)
            .then((response) => response.json())
            .then((responseJson) => {
                return responseJson
            })
            .catch((error) => {
                console.error(error);
            });
    }

    getAboutContent(){
           let aboutUrl = `${wpBase}/wp-json/wp/v2/pages?slug=about&fields=content.rendered`;
           return this.getJson(aboutUrl).then(function (json) {
               return json[0].content.rendered
           });
    }

    getContactContent(){
        let aboutUrl = `${wpBase}/wp-json/wp/v2/pages?slug=contact&fields=content.rendered`;
        return this.getJson(aboutUrl).then(function (json) {
            return json[0].content.rendered
        });
    }

    MenuItemsUrls(id) {
        const menuItemsUrl = `${wpBase}/wp-json/wp-api-menus/v2/menus/${id}`;
        return this.getJson(menuItemsUrl).then(function (menuItemsJson) {
            let menuJson = menuItemsJson;
            let menuItem = [];
            let menuItemList = [];
            menuItem.name = menuJson.name;
            menuItem.id = menuJson.ID;
            if(menuJson.count > 0) {
                for (let item in menuJson.items) {
                    let items = menuJson.items[item];
                    let subItem = [];
                    if(items.children) subItem.children = items.children;
                    subItem.id = items.id;
                    subItem.name = items.title;
                    subItem.slug = items.object_slug;
                    menuItemList.push(subItem);
                }
            }
            menuItem.menuitems = menuItemList;
            return menuItem;
        });
    }

    getSubSection(id) {
        const obj = this;
        const subSectionTextUrl = `${wpBase}/wp-json/wp/v2/pages?parent=${id}&fields=title.rendered,content.rendered`;
        return this.getJson(subSectionTextUrl).then(function (subPagesJson) {
            let subSection = {};
            if (subPagesJson.length) {
                for (let idx in subPagesJson) {
                    let subPageJson = subPagesJson[idx];
                    let title = subPageJson.title.rendered;
                    if (title === "text") {
                        subSection.text = subPageJson.content.rendered;}}
            } else {subSection.text = false;}
            const subSectionImagesUrl = `${wpBase}/wp-json/wp/v2/media?parent=${id}&per_page=100`;
            return obj.getJson(subSectionImagesUrl).then(function (mediaJson) {
                let sectionImages = [];
                for (let mediaIdx in mediaJson) {
                    let imgJson = mediaJson[mediaIdx];
                    let sectionImage = {};
                    if (imgJson.media_details.fileformat === "mp4" || imgJson.media_details.fileformat === "webm") {
                        sectionImage.src = imgJson.source_url;
                    } else {
                        if (!imgJson.media_details.sizes) {} else {
                            sectionImage.src = imgJson.media_details.sizes.full.source_url;}}
                    sectionImage.caption = imgJson.caption.rendered;
                    sectionImages.push(sectionImage);
                }
                subSection.imgs = sectionImages;
                return subSection;
            });
        });
    }

    getSection(slug) {
        const obj = this;
        const sectionUrl = `${wpBase}/wp-json/wp/v2/pages?slug=${slug}&fields=id,title.rendered`;
        return this.getJson(sectionUrl).then(function (sectionJson) {
            if (sectionJson.length > 0) {
                let sectionId = sectionJson[0].id;
                let sectionTitle = sectionJson[0].title.rendered;
                let sectionItemsUrl = `${wpBase}/wp-json/wp/v2/pages?parent=${sectionId}&fields=id,title.rendered,content.rendered,better_featured_image.source_url&order=asc&orderby=menu_order&page=1&per_page=100`;
                let section = {};
                section.text = false;
                section.title = sectionTitle;
                return obj.getJson(sectionItemsUrl).then(function (sectionsJson) {
                    let sectionItems = [];
                    for (let idx in sectionsJson) {
                        let sectionJson = sectionsJson[idx];
                        let id = sectionJson.id;
                        let title = sectionJson.title.rendered;
                        let content = false;
                        let sectionItem = {};
                        if (sectionJson.content)
                            content = sectionJson.content.rendered;
                        let img = false;
                        if (sectionJson.better_featured_image)
                            img = sectionJson.better_featured_image.source_url;
                        if (title.includes("text")) {
                            section.text = content;
                        } else if (title.includes("cover")) {
                            section.cover = img;
                        } else {
                            img = false;
                            if (sectionJson.better_featured_image)
                                img = sectionJson.better_featured_image.source_url;
                            sectionItem.title = title;
                            sectionItem.id = id;
                            sectionItem.cover = img;
                            sectionItems.push(sectionItem);
                        }
                    }
                    section.subSections = sectionItems;
                    return section;
                });
            } else {
                return false;
            }
        });
    }


    getPageItem(pageId) {
        const sectionUrl = `${wpBase}/wp-json/wp/v2/pages/${pageId}`;
        return this.getJson(sectionUrl).then(function (sectionJson) {return sectionJson;});}

    getChildrenPages(pageId) {
        const childs = `${wpBase}/wp-json/wp/v2/pages?parent=${pageId}&depth=1&per_page=100&page=1&fields=id,title.rendered,better_featured_image,parent`;
        return this.getJson(childs).then(function (ChildrenPages) {
            if(ChildrenPages) {
                if (ChildrenPages.length > 0) {
                    return ChildrenPages;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        });
    }

    getAllSubPages() {
        const allPagesUri = `${wpBase}/wp-json/wp/v2/pages?parent=${menuPageId}&per_page=100&page=1&fields=id,slug,title.rendered`;
        const obj = this;
        return obj.getJson(allPagesUri).then(function (sectionJson) {
            if(sectionJson) {
                if (sectionJson.length > 0) {
                    return sectionJson;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        });
    }
}
const Ajax = new AjaxCalls();
export { Ajax };