import React, { Component } from 'react';
import {  BrowserRouter as Router, Route } from 'react-router-dom'
import TweenMax from "gsap/TweenMax";
import {isIE} from 'react-device-detect';
import {isMobile, isTablet} from 'react-device-detect';
import './MainMenu.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './App.css';

import { About } from './components/About';
import { Contact } from './components/Contact';
import { Landing } from './components/Landing';
import { MainMenu } from './components/MainMenu';
import { Section } from './components/Section';
import {Carousel} from "./components/Carousel";


//Favicon: https://stackoverflow.com/questions/1837261/how-to-animate-a-favicon
// info mouse pointers: https://greensock.com/forums/topic/17878-div-follow-mouse/
/*
add file under root folder .htaccess
content:

<IfModule mod_rewrite.c>

  RewriteEngine On
  RewriteBase /
  RewriteRule ^index\.html$ - [L]
  RewriteCond %{REQUEST_FILENAME} !-f
  RewriteCond %{REQUEST_FILENAME} !-d
  RewriteCond %{REQUEST_FILENAME} !-l
  RewriteRule ^.*$ / [L,QSA]

</IfModule>

 */

const mobileTabletStyle = {
    "#root": {"overflowY":"scroll", "width":"100%;"},
    "*": {"width": "100%"}
};

const screenStyle = {
    "#root": {"overflow":"hidden"},
};

class App extends Component {

    constructor(props) {
        super(props);
        if (!isIE) {
            this.state = {myMousePointer: null, rootStyle: null, resize: false,contentWrapperHeight:"90%" };
            this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
        }
    }

    setMobileStyle = () => {
        if (isMobile || isTablet || (window.innerWidth < 1101)) {
            document.getElementsByTagName('div')[0].style.overflowY = mobileTabletStyle["#root"].overflowY;
            document.getElementsByTagName('div')[0].style.width = mobileTabletStyle["#root"].width;
        } else {
            document.getElementsByTagName('div')[0].style.overflowY = null;
            document.getElementsByTagName('div')[0].style.width = null;
            document.getElementsByTagName('div')[0].style.overflowY = screenStyle["#root"].overflow;
        }
    };

    componentDidMount(){
        if (!isIE) {
            this.myContentWrapper.addEventListener('resize', this.updateWindowDimensions);
            this.setMobileStyle();
            window.addEventListener('resize', this.updateWindowDimensions);
            this.updateWindowDimensions();

        }
       //this.interval = setInterval(this.faviconAnimationLoop, 1000);
        /*TweenMax.set(this.state.myMousePointer, { scale: 0, xPercent: -50, yPercent: -50 });*/
    }
    componentWillMount(){
        if (!isIE) window.removeEventListener('resize', this.updateWindowDimensions);
    }

    positionPointer(e) {
        let startPosition = 0,
        scale = 1.5;
    	const container = this.myContentBox;
        let relX = e.pageX - container.offsetLeft;
        if (window.innerWidth > 1920) {
            startPosition = 100
        } else {
            startPosition = 25
        }
        let relY = e.pageY - container.offsetTop + startPosition;
        TweenMax.to(this.state.myMousePointer, 0.3, { x: relX, y: relY });
        TweenMax.to(container, 0.3, {attr:{x:relX*(scale-1)-e.pageX*(scale-1), y:-e.pageY*(scale-1)}});

    }

    updateWindowDimensions() {
        let mainMenuHeight = null;
        this.setMobileStyle();
        if (!(isMobile || isTablet || (window.innerWidth < 1101)) && this.myContentBox) {
            mainMenuHeight = this.myContentBox.childNodes[0].offsetHeight;
            const gridHeight = window.innerHeight;
            const contentWrapperHeight = gridHeight - mainMenuHeight;
            this.setState({
                resize: true,
                contentWrapperHeight: contentWrapperHeight
            });
        } else {
            this.setState({
                resize: true,
                contentWrapperHeight: "90%"
            });
        }
    }

    faviconAnimationLoop(){
        setTimeout(function(){ document.getElementById("icon").href = "/img/favicon.ico";}, 500);
        setTimeout(function(){ document.getElementById("icon").href = "/img/white.ico";}, 1000);
    }

    mousePointerEnter(e) {
        TweenMax.to(this.state.myMousePointer, 0.3, { scale: 1, opacity: 1 });
		this.positionPointer(e);
    }

    mousePointerLeave(e) {
        TweenMax.to(this.state.myMousePointer, 0.3, { scale: 1, opacity: 1 });
        this.positionPointer(e);
	}

  render() {
      if (isIE) return (<div> IE is not supported. Download Chrome/Opera/Firefox </div>);
     /* feature cursor replace by png
     return (
	<Router>
      <div
		  className="Wrapper"
		  ref={div => this.state.myContentBox = div}
          onPointerEnter={(e) => this.mousePointerEnter(e)}
		  onPointerLeave={(e) => this.mousePointerLeave(e)}
		  onPointerMove={(e) => this.positionPointer(e)}
	  >
          <div
			  className={"mousePointer"}
			  ref={div => this.state.myMousePointer = div}
		  >
          </div>
        <Route
			path='/'
			component={MainMenu}
		/>
		<div
			className="ContentWrapper"
		>
			<Route exact path='/' component={Landing} />
			<Route path='/portfolio/:slug' component={Section}/>
			<Route exact path='/contact' component={Contact}/>
			<Route exact path='/about' component={About}/>
		</div>
      </div>
	  </Router>
    );*/
      return (
          <Router style={{color:"blue"}} basename={"/"}>
              <div
                  className="Wrapper"
                  ref={div => this.myContentBox = div}
              >
                  <Route path='/' component={MainMenu}/>
                  <div
                      className="ContentWrapper"
                      ref={div => this.myContentWrapper = div}
                  >
                      <Route exact path='/portfolio/:slug' component={Section}/>
                      <Route exact path='/portfolio/:slug/:index'  component={Carousel}/>
                      <Route exact path='/Contact' component={Contact}/>
                      <Route exact path='/About' component={About}/>
                      <Route exact path='/' component={Landing} />
                  </div>
              </div>
          </Router>
      );

  }
}

export default App;
