import React, { Component } from 'react';
import { Ajax } from '../ajax/Ajax';
import {sFunctions} from './StaticFunctions';
import ReactHtmlParser from 'react-html-parser';
import {Textfit} from "react-textfit";

class Section extends Component {
	
	constructor(props) {
		super(props);
		this.state = {myHeight: null, section: null, overlay: false, overlays: {}};
		this.loadSubSection = this.loadSubSection.bind(this);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
	}

	loadSubSection(section, idx) {
		let obj = this;
        let subSection = section.subSections[idx];
        let subSectionId = subSection.id;
		Ajax.getSubSection(subSectionId).then(subSectionContent => {
			subSection.content = subSectionContent;
			obj.setState({section: section});
		});
	}
	
	componentWillMount() {
		window.removeEventListener('load', this.updateWindowDimensions);
		window.removeEventListener('resize', this.updateWindowDimensions);
        window.removeEventListener('orientationchange', this.updateWindowDimensions);
		let slug = this.props.match.params.slug;
		let obj = this;
		Ajax.getSection(slug).then(section => {
			obj.setState({section: section});
			for (let idx in section.subSections) {
				let subSection = section.subSections[idx];
				let roman = sFunctions.toRoman(Number(idx) + 1);
				subSection.title = `<span>${roman}. ${subSection.title}</span>`;
				obj.loadSubSection(section, idx);
			}
		});
	}
	
	componentDidMount() {
        window.addEventListener('load', this.updateWindowDimensions);
        window.addEventListener('resize', this.updateWindowDimensions);
        window.addEventListener('orientationchange', this.updateWindowDimensions);
        this.updateWindowDimensions();
	}

    updateWindowDimensions() {
        if (!(sFunctions.mobileDevice()) && document.getElementsByClassName('MainMenu')[0]) {
            this.setState({myHeight: window.innerHeight - document.getElementsByClassName('MainMenu')[0].offsetHeight});
        }
    }
    getTitleBlock(){
		const obj = this;
		const section = this.state.section;
        const sectionTitle = sFunctions.clearAmp(section.title.replace('white-space: nowrap;', ''));
        const sectionText = section.text;
        const subSections = section.subSections;
        let slug = this.props.match.params.slug;
        const height = sFunctions.setHeight();
        let textFiSettings =  {
            max:  height,
            forceSingleModeWidth: false,
            mode: "multi"
        };
		return (
            <div key="titleblock" className="SectionBlock SectionBlockText">
                <div className="SectionTitle">
                    <Textfit  {...textFiSettings} >
                        <p style={{height:height }}>
					{sectionTitle}
                        </p>
                    </Textfit>
					</div>
                {sectionText ? (
                	<div className="SectionBlockTextContent" >
						{ReactHtmlParser(sectionText)}</div>) : null }
                {subSections ? subSections.map((subSection, key) => (
                    <div className="SectionBlockMenu" key={key}>
                        <a
                            href={"#"}
                            onClick={() => {
                                obj.goToCarousel(subSection.id, slug)
                            }}
                        >
                            {ReactHtmlParser(subSection.title)}
                        </a>
                    </div>
                )) : null}
            </div>
		)
	}

    setSectionBlock(subSections){
		const obj = this;
        let slug = this.props.match.params.slug;
		let blocks = [];
        if (subSections) {
            subSections.map((subSection, key) => {
                let title = subSection.title;
                let subSectionBlocks = [];
                let subTitle = (
                    <div
                        key={key + title} className={"SubSectionTitleWrap"}>
                        <div id={subSection.id} className="SubSectionTitle">
                            {ReactHtmlParser(subSection.title)}
                        </div>
                    </div>);
                subSectionBlocks.push(subTitle);
                if (subSection.content && subSection.content.imgs) {
                    let img;
                    if (subSection.cover) {
                        img = {src: subSection.cover};
                    } else {
                        img = subSection.content.imgs[0];
                    }
                    if (img) {
                        let imgBlock = (
                            <div
                                key={key}
                                onClick={() => {
                                    obj.goToCarousel(subSection.id, slug)
                                }}
                                className={"SectionBlock"}
                                style={{backgroundImage: "url('" + img.src + "')"}}>
                            </div>);
                        subSectionBlocks.push(imgBlock);
                    }
                }
                blocks.push(subSectionBlocks);
            });
        } else {
            blocks = null;
        }
		return blocks
    }

    getMobileSectionBlocks(subSections){
        const obj = this;
        const height = window.innerHeight * 40/100;
        let slug = this.props.match.params.slug;
        let blocks = [];
        let imgBlock;
        if (subSections) {
            subSections.map((subSection, key) => {
                let title = subSection.title;
                if (subSection.content && subSection.content.imgs) {
                    let img;
                    if (subSection.cover) {
                        img = {src: subSection.cover};
                    } else {
                        img = subSection.content.imgs[0];
                    }
                    if (img) {
                        imgBlock = (
                            <div key={key} onClick={() => {
                                obj.goToCarousel(subSection.id, slug)
                            }}
                                 className={"portfolioBlockImage"}
                            ><img className={"portfolioBlockImageImg"} src={img.src} alt={slug}/></div>);
                    }
                }
                let block = (
                    <div key={key + title} className={"portfolioBlock"} style={{height: height}}>
                        <div id={subSection.id} className="portfolioTitleBlockTitle">
                            {ReactHtmlParser(subSection.title)}
                        </div>
                        {imgBlock}
                    </div>);
                blocks.push(block);
            });
        } else {
            blocks = null;
        }
        return blocks
    }
    getMobileTitleBlock(){
        const section = this.state.section;
        const sectionTitle = section.title;
        const sectionText = section.text;
        return (
            <div key="titleblock" className="PortfolioTitleBlock">
                <div className="PortfolioTitleBlockTitle">
                        <p>
                            {sFunctions.clearAmp(sectionTitle)}
                        </p>
                </div>
                {sectionText ? (
                    <div className="PortfolioTitleBlockContent" >
                        {ReactHtmlParser(sectionText)}</div>) : null }
            </div>
        )
    }
	goToCarousel(id, slug){
        this.props.history.push(`/Portfolio/${slug}/${id}`)
	}

	render() {
        const section = this.state.section;
        if(section) {
            const sectionCover = section.cover;
            const sectionTitle = section.title;
            const subSections = section.subSections;
            if (!(sFunctions.mobileDevice())) {
                let itemsSub = this.setSectionBlock(subSections);
                return (
                    <div key={sectionTitle} className="Section"
                         style={{height: this.state.myHeight}}>
                        {this.getTitleBlock()}
                        {sectionCover ? (
                            <div className="SectionBlock SectionCover"
                                 style={{backgroundImage: "url('" + sectionCover + "')"}}
                            />) : null}
                        {sectionCover ? (<div className="Clear"/>) : null}
                        {itemsSub}
                    </div>
                );
            } else {
                let subMobile = this.getMobileSectionBlocks(subSections);
                return (
                    <div className={"PortfolioMobile"}>
                        {this.getMobileTitleBlock()}
                        {sectionCover ? (
                            <div className="PortfolioTitleBlockImage"
                                  >
                                <img alt={""} src={sectionCover} />
                            </div>) : null}
                            <div className={"PortfolioImages"}>
                                {subMobile}
                                </div>
                        </div>
                );
            }
        } else {return null}
	}
}

export { Section };