import {isMobile, isTablet} from "react-device-detect";
import Draggable from "react-draggable";
import React from "react";
import MouseTooltip from "react-sticky-mouse-tooltip";

const imfDimensionWidth = 918;
const imfDimensionHeight = 1192;

class StaticFunctions {

    clearAmp = text => {
        if (text) return text.replace("&#038;", "&")
    };

    toRoman = num => {
        let result = '';
        const decimal = [1000, 900, 500, 400, 100, 90, 50, 40, 10, 9, 5, 4, 1];
        const roman = ["M", "CM", "D", "CD", "C", "XC", "L", "XL", "X", "IX", "V", "IV", "I"];
        for (let i = 0; i <= decimal.length; i++) {
            while (num % decimal[i] < num) {
                result += roman[i];
                num -= decimal[i];
            }
        }
        return result;
    };

    static getImageDimension(contentHeight) {
        return (imfDimensionWidth / imfDimensionHeight) * contentHeight
    };

    mobileDevice = () => isMobile || isTablet || (window.innerWidth < 1101);

    isVisible = element => {
        let check = element.getBoundingClientRect();
        return check.left >= 0 && check.right <= window.innerWidth && check.bottom > 0 && check.right > 0 &&
            check.left < (window.innerWidth || document.documentElement.clientWidth) &&
            check.top < (window.innerHeight || document.documentElement.clientHeight);
    };

    randomNumerExclude = (indexToExclude, lengthOfArray) => {
        let rand = null;
        while (rand === null || rand === indexToExclude) {
            rand = Math.round(Math.random() * (lengthOfArray - 1));
        }
        return rand;
    };

    imgPopUpOnMouseEnter(visible) {
        if(visible){
            this.myImageToolTip.style.visibility = "visible";
            this.myImageToolTip.style.opacity = "1";
        } else {
            this.myImageToolTip.style.visibility = "hidden";
            this.myImageToolTip.style.opacity = "0";
        }
    };

    imgPopUpOnMouseLeave() {
        this.myImageToolTip.style.visibility = "hidden";
        this.myImageToolTip.style.opacity = "0";
    };

    setHeight() {
        let fac = 0;
        if (this.mobileDevice()) {
            fac = (window.innerHeight / 100) * 3.5
        } else {
            fac = (window.innerHeight / 100) * 3.5
        }
        return fac
    };

    draggableImage(startX, content ) {
        if(content) {
            let elementHeader = content.parentElement.parentElement;
            let realHeight = window.innerHeight - elementHeader.childNodes[0].offsetHeight;
            let imgW = StaticFunctions.getImageDimension(realHeight);
            let start = startX - imgW / 2;
            return (
                <div className="dragBoxDiv">
                    <Draggable
                        handle=".handleDragImage"
                        scale={1}
                        position={null}
                        offsetParent={content}
                        defaultPosition={{x: start, y: 0}}
                    >
                        <div style={{height: "100%"}}>
                            <img src={'/img/logo-white-blur.png'}
                                 draggable="false"
                                 onMouseEnter={() => {
                                     this.imgPopUpOnMouseEnter(true)
                                 }}
                                 onMouseLeave={() => {
                                     this.imgPopUpOnMouseEnter(false)
                                 }}
                                 style={{height: realHeight, width: imgW}}
                                 alt="" className="handleDragImage"
                            />
                        </div>
                    </Draggable>
                    <MouseTooltip offsetX={10} offsetY={10} style={{zIndex: 99999}}>
                        <span ref={img => this.myImageToolTip = img} className="toolTipText">drag me</span>
                    </MouseTooltip>
                </div>
            )
        }
    };

    makeImage(contentWrapperWidth, contentWrapperHeight, myContent){
        let startX = contentWrapperWidth / 2;
        let draggableImage= null;
        if(startX > 0 && sFunctions.mobileDevice() === false) {
            draggableImage=this.draggableImage(startX,myContent, contentWrapperHeight)
        }
        return draggableImage
    };
}

let sFunctions = new StaticFunctions();

export { sFunctions };