import React, { Component } from 'react';
import { Ajax } from '../ajax/Ajax';
import ReactHtmlParser from 'react-html-parser';
import {sFunctions} from './StaticFunctions';

class About extends Component {
    constructor(props) {
        super(props);
        this.state = {content: "",contentWrapperHeight: null,imgWidth:0,contentWrapperWidth:0, imageDrag: null};
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);

    }

	componentDidMount() {
        window.addEventListener('resize', this.updateWindowDimensions);
        window.addEventListener('orientationchange', this.updateWindowDimensions);
       // if (document.attachEvent) document.attachEvent('onclick', sFunctions.mouseHandler);
        //else document.addEventListener('click', sFunctions.mouseHandler);
        this.myContent.addEventListener('resize', this.updateWindowDimensions);
    	let obj = this;
        Ajax.getAboutContent().then(function(PageContent) {
            obj.setState({content: PageContent});
            obj.updateWindowDimensions();
		});
        this.updateWindowDimensions();}

	componentWillMount(){
        window.removeEventListener('orientationchange', this.updateWindowDimensions);
        window.removeEventListener('resize', this.updateWindowDimensions);
	}

    updateWindowDimensions(){
		if(sFunctions.mobileDevice()){
            this.setState({contentWrapperHeight: "100%"})
		} else {
            this.setState({contentWrapperHeight:
                document.getElementsByClassName('ContentWrapper')[0].offsetHeight,
                contentWrapperWidth:window.innerWidth})}}

	render() {
        return (
                <div ref={div => this.myContent = div} style={{height: this.state.contentWrapperHeight}}>
                    <div className="About">{ReactHtmlParser(this.state.content)}</div>
                    {sFunctions.makeImage(this.state.contentWrapperWidth, this.state.contentWrapperHeight, this.myContent)}
                </div>
            );}
}

export { About };