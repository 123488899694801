import React, { Component } from 'react';
import { Ajax } from '../ajax/Ajax';
import Menu, { SubMenu, Item as MenuItem } from 'rc-menu';
import {sFunctions} from './StaticFunctions';
import ReactDOMServer from 'react-dom/server';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight } from '@fortawesome/free-solid-svg-icons';

import { faCaretDown} from '@fortawesome/free-solid-svg-icons';
library.add(faCaretRight);
library.add(faCaretDown);

const menuIds = [[4,"Fashion Web Commerce"],[3,"Graphic Design"],[7, "Personal Work"],[6,"Textile Prints"]];
const base = "";


/*menus: http://kimtdesign.info/wordpress/wp-json/wp-api-menus/v2/menus/
Single component docs: http://react-component.github.io/menu/examples/single.html
Horizontal menu docs: http://react-component.github.io/menu/examples/antd.html
http://react-component.github.io/menu/
 */

/* TODO: Set submenu height and overflow;; try to set css style in submenu to align more to the left, margin negative?
overflow: auto
height --> window.height - MainMenu
 */

let screenY = 0;
class MainMenu extends Component {

	constructor(props) {
		super(props);
		this.state = {
            menuItems: [],
            loadingItems: false,
            currentHoverItem: null,
            borderColor: "transparent",
            borderStyle: "solid",
            textColor: "",
            border: false,
            resize: false,
            myElementMobileLander: null,
            myElementMobileLanderChild: null,
            mobileMenuShowImage: true,
            mobileMenuClass: "MainMobileMenuImage",
            mobileContent: [],
            styleVisible: "visible",
            mobileMenuMain: true,
            contactAboutBlock: [],
            myMobileBottomMenu: null,
            myMobileBottomMenuImage: null,
            myMobileBottomMenuImageVisible: "hidden",
            arrowRight: (<FontAwesomeIcon icon="caret-right" />),
        };
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
	}

	setBottomImage() {
        this.setState({styleVisibleImageBottom: "visible"})
    }

	componentWillMount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
        window.removeEventListener('orientationchange', this.updateWindowDimensions);
    }
    giveMobileBottom(visibility){
	    let styleDefault = {visibility: visibility};
        if (this.props.location.pathname !== "/") {
        }
	    return (
	        <div className={"mobileContactAboutBlock"} key={"mobileMainMenu"}>
                <div key={"mobileBottomMenu"}>
                    <div className="mobileMainMenuBottom"  style={{float: "left",textAlign: "left"}} key={"mobileBottomMenuAbout"}>
                        <p onClick={() => {this.setState({styleVisible: "hidden"});this.props.history.push('/About')}}>About</p>
                    </div>
                    <div
                        key={"mobileBottomMenuIMG"}
                        className="mobileMainMenuBottomImage"
                        style={styleDefault}
                        onClick={() =>{this.setState({styleVisibleImageBottom: "visible"}); window.location.href = "/"}}
                    />
                    <div className="mobileMainMenuBottom"  style={{float: "left",textAlign: "right"}} key={"mobileBottomContact"}>
                        <p onClick={() => {this.setState({styleVisible: "hidden"});this.props.history.push('/Contact')}}>Contact</p>
                    </div>
                </div>
            </div>
            )
    }
	componentDidMount() {
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
        if(!(sFunctions.mobileDevice())) {this.myMenuItem.addEventListener('resize', this.updateWindowDimensions);}
        this.setBottomImage = this.setBottomImage(this);
        let itemArray = this.state.menuItems;
        let obj = this;
        for(let i in menuIds){
            Ajax.MenuItemsUrls(menuIds[i][0]).then(function(section) {
                itemArray[i] = section;
                obj.updateWindowDimensions();
                obj.setState({loadingItems: true});
                if (section.length > 0) {
                }
            })
        }
        window.addEventListener('orientationchange', this.updateWindowDimensions);
        window.addEventListener('resize', this.updateWindowDimensions);
	}
    updateWindowDimensions() {
        if ((!(sFunctions.mobileDevice())) && this.myMenuItem) {
            let menu = this.myMenuItem.parentElement;
            let contentHeight = menu.offsetHeight - menu.childNodes[0].offsetHeight;
            menu.childNodes[1].style.height = `${contentHeight}px`;
            this.setState({
                resize: true,
                menuHeight: menu.childNodes[0].offsetHeight,
            });
        } else {this.setState({resize: true,});}
    }

    onOpenChange = (openKeys) => {
        this.setState({
            openKeys,
        });
        this.setSubMenuHeight()
    };

	setSubMenuHeight = () => {
	    if(!(sFunctions.mobileDevice())) {
            let windowSizeVar = 0.5;
            if (window.innerWidth > 1920) windowSizeVar = 0.75;
            let subMenus = document.getElementsByClassName("rc-menu-submenu-popup");
            const mainMenuHeight = document.getElementsByClassName("MainMenu")[0].offsetHeight;
            const menuHeight = `${window.innerHeight - mainMenuHeight - windowSizeVar}px`;
            for (let i = 0; i < subMenus.length; i++) {
                subMenus[i].getElementsByClassName('rc-menu-vertical')[0].style.height = menuHeight;
                subMenus[i].style.height = menuHeight;
            }
        }
	};
/*
    hoverOn(e){
		let element = e.domEvent.target;
        /*computedStyle = window.getComputedStyle(element);
        let animation = element.style.animation
        console.log(animation);
        console.log(computedStyle)
        //e.domEvent.target.style.outline = "solid 5px #FC5185";
        //element.style.borderColor = "inherit";
        element.className += " " + 'rc-menu-text-color';
        element.style.color = "green";
    }
    hoverOff(e){
        let element = e.domEvent.target;
       // element.classList.remove('rc-menu-text-color');
        //Array.from(computedStyle).forEach(key => element.style.setProperty(key, computedStyle.getPropertyValue(key), computedStyle.getPropertyPriority(key)));
        //element.style.borderColor = "transparent";
        //element.style.color = "unset";

    }
    */
    handleScroll = e => {
        let delta = Math.max(-1, Math.min(1, (e.deltaY || - e.deltaMode)));
        let imageGrid = document.getElementById('root');
        imageGrid.scrollTop  += (delta*60);
    };

    handleTouchStart = e => {
        screenY = e.touches[0].screenY;
    };
    handleTouchMove = e => {
        let imageGrid = document.getElementById('root');
        imageGrid.scrollTop -= (e.touches[0].screenY - screenY);
        screenY = e.touches[0].screenY;
    };

    getMobileMainMenu() {
        const menuItems = this.state.menuItems;
        let menu = [];
        let menuItemsCount = 0;
        let ItemsCount = 0;
        for(let items in menuItems) {
            this.setState({mobileMenuMain: false});
            let item = menuItems[items];
            let tittle = item.name;
            let count = item.menuitems.length;
            let children = [];
            if(count > 0) {
                for (let i in item.menuitems) {
                    let subItems = item.menuitems[i];
                    const id = ItemsCount;
                    if (subItems.children) {
                        let childrenSub = [];
                        for(let y in subItems.children) {
                            let url = (subItems.children[y].url).split("wordpress/")[1];
                            let count = (url.split("/").length - 1);
                            const parentSlug = url.split("/")[1];
                            childrenSub.push(
                                <p key={`${String(menuItemsCount)}-${String(i)}-${String(y)}`}
                                ><a href={(count === 2) ?
                                    (`${base}/Portfolio/${subItems.children[y].object_slug}`) :
                                    (`${base}/Portfolio/${parentSlug}/${subItems.children[y].object_id}`)
                                }>
                                    {sFunctions.clearAmp(subItems.children[y].title)}
                                </a>
                                </p>);
                        }
                        const childrenSubSub = (<ul className={"MobileMainMenuCollapse"}>{childrenSub}</ul>);
                        children.push(
                            <ul
                                onClick={() => this.subSubMenuCheckActive(`menuItems-${String(id)}`, childrenSubSub)}
                                key={`menuItems-${String(id)}`}
                                id={`menuItems-${String(id)}`}
                                data-is-clicked={"false"}
                            >
                                <div className={"MobileSubMenuItemSub"}>{sFunctions.clearAmp(subItems.name)}{this.state.arrowRight}</div>
                            </ul>);

                    } else {
                        children.push(
                            <p
                                onClick={() => {
                                    window.location.href = `${base}/Portfolio/${subItems.slug}`
                                }}
                                key={`${tittle}-${String(subItems.name)}-${String(i)}`}
                                id={`menuItems-${String(id)}`}
                            >
                                {sFunctions.clearAmp(subItems.name)}
                            </p>);
                    }
                    ItemsCount ++;
                }
            }
            menu.push(<p
                onClick={() => {this.handleMobileMenuShowSub(children)}}
                key={`mm-${String(items)}`}
            >
                {tittle}
            </p>);
            this.setState({mobileMenuMain: true})
        }
        return(
            <div>
                <div
                    onClick={() => {window.location.href = `${base}`}}
                    className="mobileMainMenuImage"
                >
                </div>
                <div  className="mobileMainMenuMiddle">{menu}</div>
                {this.giveMobileBottom("hidden")}
            </div>
        )
    }

    handleMobileMenuShowSub(subItems){
        let subMenu = [];
        subMenu.push(<div key="mobileSubmenuItems" className="mobileSubmenuItems">{subItems}</div>);
        subMenu.push(this.giveMobileBottom("visible"));
        this.setState({
            mobileMenuClass: "MainMobileMenuStartMenuSub",
            mobileMenuShowImage: false,
            mobileContent:subMenu,
        });
    }

    handleMobileMenuShow(){
        if(this.state.mobileMenuShowImage !== false && this.state.menuItems.length > 3) {
            let menuItems = this.getMobileMainMenu();
            this.setState({
                mobileMenuClass: "MainMobileMenuStartMenu",
                mobileMenuShowImage: false,
                mobileContent: menuItems
            });
        }
    }

    getSubMenuItems() {
        const menuItems = this.state.menuItems;
        let menuItemsCount = 0;
        let ItemsCount = 0;
		let menu = [];
    	for(let items in menuItems) {
    		let childItem = menuItems[items];
            let tittle = childItem.name;
            let count = childItem.menuitems.length;
            let children = [];
            if(count > 0) {
                for (let i in childItem.menuitems) {
                    let subItems = childItem.menuitems[i];
                    const id = ItemsCount;
                    if(subItems.children) {
                        let childrenSub = [];
                        for(let y in subItems.children) {
                            let url = (subItems.children[y].url).split("wordpress/")[1];
                            let count = (url.split("/").length - 1);
                            const parentSlug = url.split("/")[1];
                            childrenSub.push(
                                <li
                                    key={`${String(menuItemsCount)}-${String(i)}-${String(y)}`}>
                                    <a href={(count === 2) ?
                                            (`${base}/Portfolio/${subItems.children[y].object_slug}`) :
                                            (`${base}/Portfolio/${parentSlug}/${subItems.children[y].object_id}`)
                                    }>
                                        {sFunctions.clearAmp(subItems.children[y].title)}
                                    </a>
                                </li>);
                        }
                        const childrenSubSub = (<ul className={"MainMenuCollapse"}>{childrenSub}</ul>);
                        children.push (
                            <MenuItem title={sFunctions.clearAmp(subItems.name)}
                                      id={`menuItems-${String(id)}`}
                                      onMouseEnter={() => this.subSubMenuCheckActive(`menuItems-${String(id)}`, childrenSubSub)}
                                      onMouseLeave={() => this.subSubMenuCheckActive(`menuItems-${String(id)}`, childrenSubSub)}
                                      key={`menuItems-${String(id)}`}
                                      data-is-clicked={"false"}
                                    >
                                <div className={"subMenuItemSub"}>{sFunctions.clearAmp(subItems.name)}{this.state.arrowRight}</div>
                            </MenuItem>);
                    } else {
                        children.push(
                            <MenuItem
                                onClick={() => {window.location.href = `${base}/Portfolio/${subItems.slug}`}}
                                id={`menuItems-${String(id)}`}
                                key={`menuItems-${String(id)}`}
                            >
                                <p>{sFunctions.clearAmp(subItems.name)}</p>
                            </MenuItem>);
                    }
                    ItemsCount ++;
                }
            }
            menu.push(
            	<SubMenu key={String(menuItemsCount)} title={sFunctions.clearAmp(tittle)} popupOffset={[-20, 10]}>
					{children}
				</SubMenu>
			);
            menuItemsCount ++;
		}
		return menu
    }
    subSubMenuCheckActive(id, subMenu) {
        let menu = document.getElementById(id);
        const menuItemClicked = menu.getAttribute("data-is-clicked");
        if(menuItemClicked === "false") {this.subSubMenuAdd(id, subMenu, menu);} else {this.subSubMenuRemove(id, menu);}
    }
    subSubMenuRemove = (id, menuItem) => {
        let arrowDown;
        let arrowN = ReactDOMServer.renderToStaticMarkup(<FontAwesomeIcon icon="caret-right"/>);
        arrowDown = new DOMParser().parseFromString(arrowN, 'text/html');
        arrowDown = arrowDown.body.firstChild;
        menuItem.getElementsByTagName("svg")[0].replaceWith(arrowDown);
        let elements = menuItem.getElementsByClassName('MainMenuCollapse');
        while (elements.length > 0) {elements[0].parentNode.removeChild(elements[0]);}
        elements = menuItem.getElementsByClassName('MobileMainMenuCollapse');
        while (elements.length > 0) {elements[0].parentNode.removeChild(elements[0]);}
        menuItem.setAttribute("data-is-clicked", "false");
    };

    subSubMenuAdd = (id, subMenu, menuItem) => {
        let arrowDown;
        let arrowN = ReactDOMServer.renderToStaticMarkup(<FontAwesomeIcon icon="caret-down"/>);
        arrowDown = new DOMParser().parseFromString(arrowN, 'text/html');
        arrowDown = arrowDown.body.firstChild;
        menuItem.getElementsByTagName("svg")[0].replaceWith(arrowDown);
        let sMenu = ReactDOMServer.renderToStaticMarkup(subMenu);
        menuItem.innerHTML += sMenu;
        menuItem.setAttribute("data-is-clicked", "true");
    };

  	render() {
		if (sFunctions.mobileDevice()) {
            if (this.props.location.pathname === "/") {
                return (
                    <div
                        className={this.state.mobileMenuClass}
                        onWheel={(e) => this.handleScroll(e)}
                        onTouchMove={(e) => this.handleTouchMove(e)}
                        onTouchStart={(e) => this.handleTouchStart(e)}
                        onClick={(e) => this.handleMobileMenuShow(e)}
                        style={{visibility: this.state.styleVisible}}
                    >
                        <div
                            key={"bottomMenu"}
                        >{this.state.mobileContent}
                        </div>
                    </div>
                )
            } else {
                return (this.giveMobileBottom("visible"))
            }
        } else {
			return(
                <div className="MainMenu" ref={div => this.myMenuItem = div} style={{height: this.state.menuHeight}}>
                    <div>
						<Menu
							mode="horizontal"
							onOpenChange={this.onOpenChange}
							openKeys={this.state.openKeys}
						>
							{this.getSubMenuItems()}
                            <Menu
                                mode="horizontal"
                                style={{float:"right", display: "inline-block"}}
                            >
                                <MenuItem onClick={() => {this.props.history.push('/About')}}
                                          className="aboutPage" >About</MenuItem>
                                <MenuItem onClick={() => {this.props.history.push('/Contact')}}
                                          className="contactPage" >Contact</MenuItem>
                                <MenuItem onClick={() => {this.props.history.push('/')}}
                                          className="homeButton" >Home</MenuItem>
                            </Menu>
                        </Menu>
					</div>
                </div>
            )
		}
	}
}

export { MainMenu };